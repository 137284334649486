.ql-toolbar.ql-snow,
.ql-container.ql-snow {
  border: none !important;
}
.ql-snow .ql-picker {
  color: rgba(255, 255, 255, 0.683) !important ;
}
.ql-snow .ql-stroke {
  stroke: rgba(255, 255, 255, 0.683) !important;
}
.ql-toolbar.ql-snow .ql-formats {
  padding: 8px 15px;
  background-color: #333333;
  border-radius: 10px;
  margin: 0px 5px 5px 0px;
  color: white;
}
.ql-container {
  margin: 10px 0px;
  background-color: rgba(255, 255, 255, 0.048);
  border-radius: 10px;
  color: white;
}
.ql-container {
  font-family: "Rethink Sans", sans-serif !important;
}
