.content h1,
.content h2,
.content h3,
.content h4,
.content h5,
.content h6{
    color: white;
    font-size: 22px;
    margin:20px 0px 10px 0px;
}
.content .ql-size-large {
  font-size: 28px;
}
.content p {
  font-size: 18px;
  line-height: 27px;
  margin-bottom: 15px;
}
.content ol,
.content ul {
  padding: 0px 10px;
  margin: 0px 10p;
}
.content ol li,
.content ul li {
  padding: 10px 0px;
  margin: 5px 20px;
}
.content ol li {
  list-style: decimal;
  font-size: 18px;
}
.content ul li {
  list-style: disc;
  font-size: 18px;
}
